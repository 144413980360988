import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet";

import Layout from "../components/Layout";
import HTMLContent from "../components/Content";
import "../styles/contact.scss";
import Map from "../components/Map";

export const ContactPageTemplate = props => {
  const { page, home } = props;

  return (
    <article className="contact">
      <div className="contact-container container">
        <section className="contact-header">
          <div className="contact-titleWrapper">
            <h1 className="contact-title">{page.frontmatter.title}</h1>
          </div>
        </section>
        <section className="contact-content section">
          {page.bodyIsMarkdown ? (
            <ReactMarkdown className="contact-description" source={page.html} />
          ) : (
              <HTMLContent className="contact-description" content={page.html} />
            )}
          <p className="contact-mapNote">{home.mapsNote}</p>
          <div className="contact-mapWrapper">
            <Map
              isMarkerShown
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB9OOZoACYaUM7kT0Y-YKtICqaOfKNP8QI&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              link={home.location.mapsLink}
              latitude={home.location.mapsLatitude}
              longitude={home.location.mapsLongitude}
            />
          </div>
        </section>
      </div>
    </article>
  );
};

const ContactPage = ({ data }) => {
  const { markdownRemark: page, footerData, navbarData, home } = data;
  const {
    frontmatter: {
      seo: { title: seoTitle, description: seoDescription, browserTitle },
    },
  } = page;

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <title>{browserTitle}</title>
      </Helmet>
      <ContactPageTemplate page={{ ...page, bodyIsMarkdown: false }} home={home.edges[0].node.frontmatter} />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactPage;

export const ContactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title        
        seo {
          browserTitle
          title
          description
        }
      }
    },
    home: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "home-page" } } }) {
      edges {
        node {
          html
          frontmatter {
            mapsNote
            location {
              mapsLatitude
              mapsLink
              mapsLongitude
              name
            }
          }
        }
      }
    }
    ...LayoutFragment
  }
`;
